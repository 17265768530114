import axios from 'axios'
import {Message } from 'element-ui'
import config from "../config"
import router from "@/router";
// import store from '@/store'


const service = axios.create({
    baseURL: config.baseUrl,
    timeout: 30000, // 请求超时时间，设置15秒。有些接口事务多能会长
})

// 请求发起拦截
service.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token")
        if(token) config.headers.eventToken = token
        if(!config.headers.appgroup) config.headers.appgroup= '6500321af572abeeffdb6a2a'
        if (config.method === 'get') {
            // 统一增加时间戳
            //config.params['d'] = new Date().getTime()
        }
        return config
    },
    (error) => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    },
)

// 请求响应拦截
service.interceptors.response.use(
    (response) => {
        const res = response.data
        if (res.code !== 0) {
            if (res.code === 401) {
                Message.warning("登录已过期，请重新登录。")
                router.push('/')
            } else if (res.code === 500) {
                Message({
                    message: '程序开小差了，请稍后再试！',
                    type: 'error',
                    duration: 5 * 1000,
                })
            } else {
                Message({
                    message: res.msg || 'Error',
                    type: 'error',
                    duration: 5 * 1000,
                })
            }
            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res
        }
    },
    (error) => {
        let messageText = ''
        let { message, response} = error
        if (message === 'Network Error') {
            messageText = 'ERROR:接口连接异常'
        } else if (message.includes('timeout')) {
            messageText = 'ERROR:系统接口请求超时'
        } else if (message.includes('Request failed with status code') && response.status !== 401) {
            messageText = 'ERROR:系统接口' + message.substr(message.length - 3) + '异常'
        }
        if(messageText){
            Message({
                message: messageText,
                type: 'error',
                duration: 5 * 1000,
            })
        }
        if(response.status === 401){
            const path = router.currentRoute.path
            if(path !== '/'){
                Message({
                    message: '登录已过期，请重新登录。',
                    type: 'error',
                    duration: 5 * 1000,
                })
                router.push('/')
            }
        }
        return Promise.reject(error)
    },
)

export default service
