<template>
  <div class="home">
    <div class="header">
      <span class="web-title">数据分析</span>
      <div class="user">
        <span class="user-name">{{userInfo.username || '管理员'}}</span>
        <span class="login-out" @click="logout">退出登录</span>
      </div>
    </div>
    <div class="content">
      <div class="menus">
        <el-menu
            :default-active="defaultActive"
            class="menu-vertical"
            @open="handleOpen"
            @close="handleClose">
          <router-link to="/home">
            <el-menu-item index="1">
              <i class="el-icon-mobile-phone"></i>
              <span slot="title">终端数据</span>
            </el-menu-item>
          </router-link>
          <router-link to="/income">
            <el-menu-item index="2">
              <i class="el-icon-bank-card"></i>
              <span slot="title">收益评估</span>
            </el-menu-item>
          </router-link>
          <router-link to="/userAnalysis">
            <el-menu-item index="3">
              <i class="el-icon-user"></i>
              <span slot="title">用户分析</span>
            </el-menu-item>
          </router-link>
          <router-link to="/preferenceAnalysis">
            <el-menu-item index="4">
              <i class="el-icon-collection"></i>
              <span slot="title">内容喜好分析</span>
            </el-menu-item>
          </router-link>
          <router-link to="/analysis_view">
            <el-menu-item index="5">
              <i class="el-icon-collection"></i>
              <span slot="title">运维大屏</span>
            </el-menu-item>
          </router-link>
          <router-link to="/data_analysis">
            <el-menu-item index="6">
              <i class="el-icon-collection"></i>
              <span slot="title">短视频大屏</span>
            </el-menu-item>
          </router-link>
        </el-menu>
      </div>
      <div class="router-view">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultActive: '1',
      userInfo:{}
    }
  },
  mounted() {
    const route = this.$route
    switch (route.fullPath) {
      case "/home":
        this.defaultActive = '1'
        break
      case "/income":
        this.defaultActive = '2'
        break
      case "/userAnalysis":
        this.defaultActive = '3'
        break
      case "/preferenceAnalysis":
        this.defaultActive = '4'
        break
      case "/analysis_view":
        this.defaultActive = '5'
        break
      case "/data_analysis":
        this.defaultActive = '6'
        break
    }
    const userInfo = localStorage.getItem('userInfo')
    if(userInfo){
      this.userInfo = JSON.parse(userInfo)
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    logout(){
      this.$confirm('确定退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.removeItem('token')
        this.$router.push('/')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: 120px;
  background: #0E8A6E;
  display: flex;
  flex-direction: row;
  .web-title {
    flex: 1;
    display: inline-block;
    line-height: 80px;
    padding-left: 40px;
    font-size: 2.5rem;
    color: #fff;
  }
  .user{
    line-height: 80px;
    padding-right: 20px;
    .user-name{
      color: #fff;
      padding-right: 10px;
      font-weight: bold;
      font-size: 15px;
    }
    .login-out{
      color: #ddd;
      cursor: pointer;
      &:hover{
        color: #fff;
      }
    }
  }
}

.content {
  background: #F5F6F7;
  border-radius: 20px 20px 0 0;
  height: calc(100vh - 80px);
  margin-top: -40px;
  display: flex;
  overflow: hidden;
  flex-direction: row;
}

.menus {
  width: 240px;
  flex: 0 0 240px;
  background: #fff;
  height: 100%;
  overflow-y: auto;
}

.router-view {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
<style>
.menu-vertical .el-menu-item.is-active {
  color: #0E8A6E;
}
</style>
