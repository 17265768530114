import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "../views/login.vue"

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name:"Login",
        meta:{
            title:"登录",
        },
        component: Login
    },
    {
        path: "/analysis",
        name:"Analysis",
        meta:{
            title:"运维大屏",
        },
        component: () => import("../views/analysis.vue"),
    },
    {
        path: "/shortVideoAnalysis",
        name:"ShortVideoAnalysis",
        meta:{
            title:"运维大屏",
        },
        component: () => import("../views/dataAnalysis.vue"),
    },
    {
        path: '/home',
        name: 'Home',
        meta:{
            title:"首页",
        },
        component: Home,
        children: [
            {
                path: '/',
                name: "TerminalData",
                meta:{
                    title:"终端数据",
                },
                component: () => import("../views/terminalData.vue"),
            },
            {
                path: '/income',
                name: "Income",
                meta:{
                    title:"收益评估",
                },
                component: () => import("../views/income.vue"),
            },
            {
                path: '/userAnalysis',
                name: "UserAnalysis",
                meta:{
                    title:"用户分析",
                },
                component: () => import("../views/userAnalysis.vue"),
            },
            {
                path: '/preferenceAnalysis',
                name: "PreferenceAnalysis",
                meta:{
                    title:"内容喜好分析",
                },
                component: () => import("../views/preferenceAnalysis.vue"),
            },
            {
                path: '/analysis_view',
                name: "Analysis",
                meta:{
                    title:"运维大屏",
                },
                component: () => import("../views/analysis.vue"),
            },
            {
                path: '/data_analysis',
                name: "DataAnalysis",
                meta:{
                    title:"短视频大屏",
                },
                component: () => import("../views/dataAnalysis.vue"),
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta && to.meta.title) {
        document.title = to.meta.title+'-数据分析';
    }
    next();
})

export default router
